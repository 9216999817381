import { IPermission } from 'src/app/core/models/user';
import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'OverAll Dashboard',
    moduleName: 'overAll-dashboard',
    iconType: 'material-icons',
    icon: 'border_all',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
    submenu: [
     {
          path: '/dashboard/overAll',
          title: 'MMR & SMR Dashboard',
          moduleName: 'overAll-dashboard',
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-menu',
          accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: false,
          badge: '',
          badgeClass: '',
          permissionCode: 'OADSMR',
          submenu: []
        
      },
      {
        path: '/dashboard/smrz-dashboard',
        title: ' SMR Dashboard',
        moduleName: 'overAll-dashboard-smr',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'SMRZ',
        submenu: []
      
    }
  ]},
  /* Meter Reading */
  {
    path: '',
    title: 'Meter Reading',
    moduleName: 'meterReading',
    iconType: 'material-icons',
    icon: 'map',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
    submenu: [
      {
        path: '',
        title: ' Dashboard',
        moduleName: 'reading-dashboard',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [{
          path: '/dashboard/main',
          title: 'Client Dashboard',
          moduleName: 'reading-dashboard',
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-menu',
          accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: false,
          badge: '',
          badgeClass: '',
          permissionCode: 'VMDMCLD',
          submenu: []
        },
        {
          path: '/dashboard/frequency',
          title: 'Frequency',
          moduleName: 'reading-dashboard',
          iconType: 'material-icons',
          icon: 'newspaper',
          class: 'ml-menu',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          permissionCode: 'VMDMFD',
          submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        }, {
          path: '/dashboard/submitted',
          title: 'Submission Dashboard',
          moduleName: 'reading-dashboard',
          iconType: 'material-icons',
          icon: 'space_dashboard',
          class: 'ml-menu',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          permissionCode: 'VMDMSD',
          submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        },
        {
          path: '/dashboard/meterReader',
          title: 'MR Dashboard',
          moduleName: 'MR-dashboard',
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-menu',
          accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: false,
          badge: '',
          badgeClass: '',
          permissionCode: 'MDMMRD',
          submenu: []
        },
          // {
          //   path: '/dashboard/internal',
          //   title: 'Internal',
          //   moduleName: 'meterReading',
          //   iconType: 'material-icons',
          //   icon: 'newspaper',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // }
        ]
      },
      //{
      //  path: '',
      //  title: 'Target',
      //  moduleName: 'reading-target',
      //  iconType: 'material-icons',
      //  icon: 'group_add',
      //  class: 'ml-sub-menu',
      //  accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      //  groupTitle: true,
      //  badge: '',
      //  badgeClass: '',
      //  submenu: [
      //    {
      //      path: '/extra-pages/blank',
      //      title: 'Target Upload',
      //      moduleName: 'reading-target',
      //      iconType: 'material-icons',
      //      icon: 'space_dashboard',
      //      class: 'ml-menu',
      //      groupTitle: false,
      //      badge: '',
      //      badgeClass: '',
      //      submenu: [],
      //      permissionCode: 'MDMTU',
      //      accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      //    }
      //  ]
      //},
      {
        path: '',
        title: 'Reading Target',
        moduleName: 'reading-target',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: true,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/target/upload/reading',
            title: 'Target Upload',
            moduleName: 'reading-target',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'MDMTU',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
           path: '/target/removal/target-removal',
            title: 'Target Removal',
            moduleName: 'reading-target',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'MRTR',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          
          {
            path: '/target/findings/scheduled-Date',
            title: 'Scheduled Date ',
            moduleName: 'reading-target-scheduled Date',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'MRSD',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/target/upload/meterno',
            title: 'Meter Number Migration',
            moduleName: 'reading-target',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'MRMN',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/target/upload/frequency',
            title: 'Frequency Migration',
            moduleName: 'reading-target',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'MRFR',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          }
        ]
      },
      {
        path: '',
        title: 'Special Reading Target',
        moduleName: 'special-reading-target',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: true,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/target/upload/special-reading',
            title: 'Target Upload',
            moduleName: 'special-reading-target',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'TUSR',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/target/upload/spl-meterno',
            title: 'MeterNumber Migration',
            moduleName: 'special-reading-MeterNumber',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'TUSM',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/target/upload/spl-frequency',
            title: 'Frequency Migration',
            moduleName: 'special-reading-Frequency-Migration',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'TUSF',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/target/upload/spl-scheduled-Date',
            title: 'ScheduledDate Migration',
            moduleName: 'special-reading-ScheduledDate-Migration',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'TUSSD',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/target/upload/spl-target-removal',
            title: 'Target Removal',
            moduleName: 'special-reading-Target-Removal',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'TUSTR',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          ]},
      {
        path: '',
        title: 'Reassignment',
        moduleName: 'reading-reassignment',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-sub-menu',
        groupTitle: true,
        badge: '',
        badgeClass: '',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        submenu: [
          // {
          //   path: '/task/meterreading/target-reasssign',
          //   title: 'Reassignment',
          //   moduleName: 'reading-reassignment',
          //   iconType: 'material-icons',
          //   icon: 'space_dashboard',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   permissionCode: 'MDMGR',
          //   badgeClass: '',
          //   submenu: [],
          //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // },
          {
            path: '/task/meterreading/reassign-bulk',
            title: 'Reassign Bulk',
            moduleName: 'reading-reassignment',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'MDMRB',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
        ]
      },
      {
        path: '/task/meterreading/unread',
        title: 'Unread',
        moduleName: 'reading-unread',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: ['MDMO'],
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      // {
      //   path: 'under_dev',
      //   title: 'Bulk Assign',
      //   moduleName: 'target',
      //   iconType: 'material-icons',
      //   icon: 'space_dashboard',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   submenu: [],
      //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      // },
      {
        path: '/task/meterreading/system-data',
        title: 'System Data',
        moduleName: 'reading-system-data',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'MDMSD',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '/task/meterreading/submitted',
        title: 'Submitted Data',
        moduleName: 'reading-submitted-data',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'MDMSUD',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '/task/meterreading/McData',
        title: 'Target Account',
        moduleName: 'reading-target-data',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'MDMTARA',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '',
        title: 'QC',
        moduleName: 'reading-qc',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/qc/meterReading/Upload/Approved',
            title: 'Approval Upload',
            moduleName: 'qc',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MDMQCAU',
            submenu: []
          },
          {
            path: '/qc/meterReading/Upload/Rejected',
            title: 'Reject Upload',
            moduleName: 'qc',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MDMQCRU',
            submenu: []
          },
          {
            path: '/qc/meterReading/Upload/ReApproved',
            title: 'Reapproval Upload',
            moduleName: 'qc',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MDMQCREU',
            submenu: []
          },
          // {
          //   path: '/qc/meterReading/QCProcess',
          //   title: 'Quality Check',
          //   moduleName: 'reading-qc',
          //   iconType: 'material-icons',
          //   icon: 'group_add',
          //   class: 'ml-menu',
          //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   submenu: []
          // },
        ]
      },
      {
        path: '',
        title: 'Submission',
        moduleName: 'reading-submission',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/submission/batch/overall',
            title: 'Batch Creation',
            moduleName: 'reding-submission',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MDMBC',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/submission/batch/list',
            title: 'Batch Submitted',
            moduleName: 'reading-submission',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MDMBL',
            submenu: [], accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
        ]
      },
      {
        path: '',
        title: 'Reports',
        moduleName: 'reding-reports',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/task/meterreading/achieved',
            title: 'Achieved',
            moduleName: 'reading-reports',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MDMACH',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/reports/all-onlyvisited',
            title: 'Only Visited',
            moduleName: 'reading-reports',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MDMOV',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/reports/MeterReaderPerformanceReport/0',
            title: 'Meter Reader Performance',
            moduleName: 'reading-reports',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MDMMRP',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/reports/SupervisorPerformance/0',
            title: 'Supervisor Performance',
            moduleName: 'reading-reports',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MDMSP',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/reports/zone',
            title: 'Zone Performance',
            moduleName: 'reading-reports',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MDMZP',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/reports/ReadsByDateReport',
            title: 'Day Wise Reading Performance',
            moduleName: 'reading-reports',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MDMDAYP',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          // {
          //   path: 'under_dev',
          //   title: 'Months Wise Reading Performance',
          //   moduleName: 'reports',
          //   iconType: 'material-icons',
          //   icon: 'group_add',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // },
          {
            path: '/reports/performanceForIncentiveReport',
            title: 'Performance for Incentive Report',
            moduleName: 'reading-reports',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MDMINCR',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/reports/Locked1Report',
            title: 'Locked-1',
            moduleName: 'reading-reports',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MDML1R',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/reports/ReadingVsDistanceReport',
            title: 'ReadingVsDistance',
            moduleName: 'reading-reports',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MDMRVD',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/reports/monthlyValidation',
            title: 'Monthly Validation Report',
            moduleName: 'reading-reports',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MDMVALR',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/reports/RevenueLossReport',
            title: 'Revenue Loss Report',
            moduleName: 'reading-reports',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MDMREVL',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          // {
          //   path: '/reports/RevenueLossReport',
          //   title: 'Day Wise Technician Performance',
          //   moduleName: '',
          //   iconType: 'material-icons',
          //   icon: 'space_dashboard',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // }
        ]
      },
      {
        path: '',
        title: 'History',
        moduleName: 'reading-history',
        iconType: 'material-icons',
        icon: 'history_toggle_off',
        class: 'ml-sub-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        submenu: [
          {
            path: '/history/account',
            title: 'Reading History',
            moduleName: 'reading-history',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MDMRH',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/history/meter-reading/reassign_history',
            title: 'Reassign History',
            moduleName: 'reading-history',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MDMRESH',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
        ]
      },
    ]
  },


  /* Site Survey */
  {
    path: '',
    title: 'Site Survey',
    moduleName: 'SiteSurvey',
    iconType: 'material-icons',
    icon: 'query_stats',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
    submenu: [
      {
        path: '',
        title: 'Dashboard',
        moduleName: 'sitesurvey-dashboard',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [{
          path: '/dashboard/site-survey/main',
          title: 'Dashboard',
          moduleName: 'sitesurvey-dashboard',
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-menu',
          accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: false,
          badge: '',
          badgeClass: '',
          permissionCode: 'SSCLD',
          submenu: []
        }]
      },
      // {
      //   path: '',
      //   title: 'Target',
      //   moduleName: 'sitesurvey-target',
      //   iconType: 'material-icons',
      //   icon: 'group_add',
      //   class: 'ml-sub-menu',
      //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      //   groupTitle: true,
      //   badge: '',
      //   badgeClass: '',
      //   submenu: [
          // {
          //   path: '/target/targetupload',
          //   title: 'Target Upload',
          //   moduleName: 'sitesurvey-target',
          //   iconType: 'material-icons',
          //   icon: 'space_dashboard',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   submenu: [],
          //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // },
      //     {
      //       path: '/extra-pages/blank',
      //       title: 'Target Upload',
      //       moduleName: 'sitesurvey-target',
      //       iconType: 'material-icons',
      //       icon: 'space_dashboard',
      //       class: 'ml-menu',
      //       groupTitle: false,
      //       badge: '',
      //       badgeClass: '',
      //       submenu: [],
      //       permissionCode: 'SSTU',
      //       accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      //     },
      //   ]
      // },
      {
        path: '',
        title: 'Reassignment',
        moduleName: 'survey-reassignment',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-sub-menu',
        groupTitle: true,
        badge: '',
        badgeClass: '',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        submenu: [
          // {
          //   path: '/task/siteSurvey/reasssign',
          //   title: 'Reassignment',
          //   moduleName: 'survey-reassignment',
          //   iconType: 'material-icons',
          //   icon: 'space_dashboard',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   permissionCode: 'SSGR',
          //   submenu: [],
          //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // },
          {
            path: '/task/siteSurvey/reassign-bulk',
            title: 'Reassign Bulk',
            moduleName: 'survey-reassignment',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'SSREB',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
        ]
      },
      {
        path: '/task/siteSurvey/open',
        title: 'Open',
        moduleName: 'sitesurvey-open',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'SSOP',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '/task/siteSurvey/system-data',
        title: 'SystemData',
        moduleName: '',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'SSSD',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '/task/siteSurvey/submitted-data',
        title: 'Submitted Data',
        moduleName: '',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'SSSUD',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '/task/siteSurvey/McData',
        title: 'TargetAccount',
        moduleName: '',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'STARAC',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '',
        title: 'QC',
        moduleName: 'QC',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: [ 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/qc/survey/Upload/Approval',
            title: 'Approval Upload',
            moduleName: '',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'SSUP',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/qc/survey/Upload/Reject',
            title: 'Reject Upload',
            moduleName: '',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'SSUR',
            submenu: [], accessLevel: [ 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          // {
          //   path: 'under_dev',
          //   title: 'Reapproval Upload',
          //   moduleName: '',
          //   iconType: 'material-icons',
          //   icon: 'space_dashboard',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   submenu: [], accessLevel: [ 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // }
        ]
      },
      {
        path: '',
        title: 'Submission',
        moduleName: 'sitesurvey-submission',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/submission/siteSurvey/batchCreation',
            title: 'Batch Creation',
            moduleName: 'sitesurvey-submission',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'SSBTC',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/submission/siteSurvey/submittedData',
            title: 'Batch Submitted',
            moduleName: 'sitesurvey-submission',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'SSBTL',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
        ]
      },
      {
        path: '',
        title: 'Reports',
        moduleName: 'sitesurvey-reports',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/task/siteSurvey/achieved',
            title: 'Achieved',
            moduleName: 'sitesurvey-achieved',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'SSACHL',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/task/siteSurvey/onlyVisit',
            title: 'Only Visit',
            moduleName: 'sitesurvey-onlyvisit',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'SSONV',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/reports/siteSurvey/DayWiseReport',
            title: 'Day Wise Report',
            moduleName: 'sitesurvey-daywise',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'SSDWR',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          // {
          //   path: 'under_dev',
          //   title: 'Month Wise Report',
          //   moduleName: '',
          //   iconType: 'material-icons',
          //   icon: 'space_dashboard',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // },
          {
            path: '/reports/siteSurvey/UserWiseReport',
            title: 'Team performance',
            moduleName: 'sitesurvey-team',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'SSTP',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          // {
          //   path: 'under_dev',
          //   title: 'Day Wise team Performance',
          //   moduleName: '',
          //   iconType: 'material-icons',
          //   icon: 'space_dashboard',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // },
        ]
      },
      {
        path: '',
        title: 'History',
        moduleName: 'sitesurvey-history',
        iconType: 'material-icons',
        icon: 'history_toggle_off',
        class: 'ml-sub-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        submenu: [
          //{
          //  path: '/history/account',
          //  title: 'Reading History',
          //  moduleName: 'history',
          //  iconType: 'material-icons',
          //  icon: 'group_add',
          //  class: 'ml-menu',
          //  groupTitle: false,
          //  badge: '',
          //  badgeClass: '',
          //  submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          //},
          {
            path: '/history/site-survey/reassign_history',
            title: 'Reassign History',
            moduleName: 'sitesurvey-history',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'SSREH',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
        ]
      },
    ]
  },

  /* Meter Replacement */
  {
    path: '',
    title: 'Meter Replacement',
    moduleName: 'MeterReplacement',
    iconType: 'material-icons',
    icon: 'published_with_changes',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
    submenu: [
      {
        path: '',
        title: 'Dashboard',
        moduleName: 'mr-dashboard',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [{
          path: '/dashboard/meter-replacement/main',
          title: 'Dashboard',
          moduleName: 'mr-dashboard',
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-menu',
          accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: false,
          badge: '',
          badgeClass: '',
          permissionCode: 'MRRCLD',
          submenu: [],
        },
        {
          path: '/dashboard/smr',
          title: 'SMR Dashboard',
          moduleName: 'SMR-dashboard',
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-menu',
          accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: false,
          badge: '',
          badgeClass: '',
          submenu: [],
          permissionCode: 'RSMRD',
        },
      {
        path: '/dashboard/area-wise-report',
          title: 'Area wise Report ',
          moduleName: 'area-wise-dashboard',
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-menu',
          accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: false,
          badge: '',
          badgeClass: '',
          submenu: [],
          permissionCode: 'AWRD',
      }]
      },
      {
       path: '',
       title: 'Target',
       moduleName: 'mr-target',
       iconType: 'material-icons',
       icon: 'group_add',
       class: 'ml-sub-menu',
       accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
       groupTitle: true,
       badge: '',
       badgeClass: '',
       submenu: [
         {
           path: '/target/upload/meter-replacement',
           title: 'Target Upload',
           moduleName: 'mr-target',
           iconType: 'material-icons',
           icon: 'group_add',
           class: 'ml-menu',
           groupTitle: false,
           badge: '',
           badgeClass: '',
           permissionCode: 'MRUT',
           submenu: [], accessLevel: ['superadmin', 'operational manager', 'quality team', 'admin'],
         },
         {
          path: '/target/upload/re-create',
          title: 'ReCreate Upload',
          moduleName: 'mr-target',
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-menu',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          permissionCode: 'MRRURC',
          submenu: [], accessLevel: ['superadmin', 'operational manager', 'quality team', 'admin'],
        }]
      },
      {
        path: '/target/upload/priority-upload',
        title: 'Priority Upload',
        moduleName: 'mr-priority-upload',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'MRRPU',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '/task/meterReplacement/open',
        title: 'Open',
        moduleName: 'mr-open',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'MRROR',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '',
        title: 'Reassignment',
        moduleName: 'replacement-reassignment',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-sub-menu',
        groupTitle: true,
        badge: '',
        badgeClass: '',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        submenu: [
          // {
          //   path: '/task/meterReplacement/reasssign',
          //   title: 'Reassignment',
          //   moduleName: 'replacement-reassignment',
          //   iconType: 'material-icons',
          //   icon: 'space_dashboard',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   submenu: [],
          //   permissionCode: 'RGR',
          //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // },
          {
            path: '/task/meterReplacement/reassign-bulk',
            title: 'Reassign Bulk',
            moduleName: 'replacement-reassignment',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'MRRRESB',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
        ]
      },
      {
        path: '/task/meterReplacement/system-data',
        title: 'SystemData',
        moduleName: 'replacement-systemdata',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'MRRSD',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '/task/meterReplacement/submitted-data',
        title: 'Submitted Data',
        moduleName: 'replacement-submitted',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'RSUD',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      }, {
        path: '/task/meterReplacement/Mcdata',
        title: 'Target Account',
        moduleName: 'replacement-mcdata',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'RTARA',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '',
        title: 'QC',
        moduleName: 'QC',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [          
          {
            path: '/qc/meterReplacement/Upload/Approved',
            title: 'Approval Upload',
            moduleName: 'replacement Qc Approval',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',           
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'REPQCAPP',
            submenu: [],
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/qc/meterReplacement/Upload/Rejected',
            title: 'Reject Upload',
            moduleName: 'replacement Qc Rejected',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'REPQCREJ',
            submenu: [],
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          // {
          //   path: 'under_dev',
          //   title: 'Reapproval Upload',
          //   moduleName: '',
          //   iconType: 'material-icons',
          //   icon: 'space_dashboard',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   submenu: [], accessLevel: [ 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // }
        ]
      },
      {
        path: '',
        title: 'Submission',
        moduleName: 'mr-submission',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/submission/meterReplacement/create',
            title: 'Batch Creation',
            moduleName: 'mr-submission',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MRRBTC',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/submission/meterReplacement/list',
            title: 'Batch Submitted',
            moduleName: 'mr-submission',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MRRBTL',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          // {
          //   path: '/submission/meterReplacement/nama-integration',
          //   title: 'Nama Integration',
          //   moduleName: 'mr-submission-nama-integration',
          //   iconType: 'material-icons',
          //   icon: 'space_dashboard',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   permissionCode: 'RSNI',
          //   submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // },
          // {
          //   path: '/target/upload/nama-repush',
          //   title: 'Rejection Repush Bulk',
          //   moduleName: 'target-nama-repush',
          //   iconType: 'material-icons',
          //   icon: 'space_dashboard',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   permissionCode: 'NAMAREPUSH',
          //   submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // },
          // {
          //   path: '/submission/meterReplacement/nama-rejection',
          //   title: 'Nama Rejection',
          //   moduleName: 'mr-submission-nama-integration',
          //   iconType: 'material-icons',
          //   icon: 'space_dashboard',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   permissionCode: '',
          //   submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // },
        ]
      },
      {
        path: '',
        title: 'NAMA Sync',
        moduleName: 'nama-sync',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/submission/meterReplacement/nama-integration',
            title: 'Sync History',
            moduleName: 'mr-submission-nama-integration',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'RSNI',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/submission/meterReplacement/nama-rejection',
            title: 'Rejection Report',
            moduleName: 'mr-submission-nama-integration',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: '',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/target/upload/nama-repush',
            title: 'Rejection Repush Bulk',
            moduleName: 'target-nama-repush',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'NAMAREPUSH',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          
        ]
      },
      {
        path: '',
        title: 'Reports',
        moduleName: 'mr-reports',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/task/meterReplacement/achieved',
            title: 'Achieved',
            moduleName: 'mr-reports',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MRACH',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/task/meterReplacement/onlyVisit',
            title: 'Only Visit',
            moduleName: 'mr-reports',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MRROV',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/reports/meterReplacement/DayWiseReport',
            title: 'Day Wise Report',
            moduleName: 'mr-reports',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'RDWTP',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/dashboard/meter-replacement/MRMonthly',
            title: 'MR Monthly Dashboard',
            moduleName: 'mr-reports',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MRRMDSH',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/reports/meterReplacement/UserWiseReport',
            title: 'Team Performance Report',
            moduleName: 'mr-reports',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MRRTP',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          // {
          //   path: '/reports/meterReplacement/UserWiseReport',
          //   title: 'Day Wise Team Performance',
          //   moduleName: 'mr-reports',
          //   iconType: 'material-icons',
          //   icon: 'space_dashboard',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   permissionCode: 'MRRDTP',
          //   submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // },
        ],
      },
      {
        path: '',
        title: 'History',
        moduleName: 'mr-history',
        iconType: 'material-icons',
        icon: 'history_toggle_off',
        class: 'ml-sub-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        submenu: [
          //{
          //  path: '/history/account',
          //  title: 'Reading History',
          //  moduleName: 'history',
          //  iconType: 'material-icons',
          //  icon: 'group_add',
          //  class: 'ml-menu',
          //  groupTitle: false,
          //  badge: '',
          //  badgeClass: '',
          //  submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          //},
          //{
          //  path: '/history/reassign_history',
          //  title: 'Reassign History',
          //  moduleName: 'history',
          //  iconType: 'material-icons',
          //  icon: 'group_add',
          //  class: 'ml-menu',
          //  groupTitle: false,
          //  badge: '',
          //  badgeClass: '',
          //  submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          //},
          {
            path: '/history/meter_replacement/reassign_history',
            title: 'Reassign History',
            moduleName: 'mr-history',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'MRRRESH',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
        ]
      },
      {
        // path: '/task/meterReplacement/data-punching',
        path: '/target/upload/data-punching',
        title: 'Data Punching',
        moduleName: 'data puching',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'RDP',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
    ]
  },

  /* Troubleshooting */
  {
    path: '',
    title: 'Troubleshooting',
    moduleName: 'Troubleshooting',
    iconType: 'material-icons',
    icon: 'construction',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
    submenu: [
      {
        path: '',
        title: 'Dashboard',
        moduleName: 'troubleshooting-dashboard',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [{
          path: '/dashboard/trouble-shooting/main',
          title: 'Client Dashboard',
          moduleName: 'troubleshooting-dashboard',
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-menu',
          accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: false,
          badge: '',
          badgeClass: '',
          permissionCode: 'TRSCLD',
          submenu: []
        },]
      },
      {
        path: '',
        title: 'Target',
        moduleName: 'troubleshooting-target',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: true,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/target/upload/troubleshooting',
            title: 'Target Upload',
            moduleName: 'troubleshooting-target',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'TRSTU',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          // {
          //   path: '/task/troubleShooting/assign',
          //   title: 'Bulk Upload',
          //   moduleName: 'troubleshooting-target',
          //   iconType: 'material-icons',
          //   icon: 'space_dashboard',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   submenu: [],
          //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // },
        ]
      },
      {
        path: '/task/troubleShooting/service-request',
        title: 'Service Request',
        moduleName: 'troubleshooting-service-request',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'TRSSR',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '/task/troubleShooting/open',
        title: 'Open',
        moduleName: 'troubleshooting-open',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'TRSOR',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '',
        title: 'Reassignment',
        moduleName: 'troubleshooting-reassignment',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-sub-menu',
        groupTitle: true,
        badge: '',
        badgeClass: '',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        submenu: [
          // {
          //   path: '/task/troubleShooting/reassign',
          //   title: 'Reassignment',
          //   moduleName: 'troubleshooting-reassignment',
          //   iconType: 'material-icons',
          //   icon: 'space_dashboard',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   submenu: [],
          //   permissionCode: 'TGR',
          //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // },
          {
            path: '/task/troubleShooting/reassign-bulk',
            title: 'Reassign Bulk',
            moduleName: 'troubleshooting-reassignment',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'TRSRESHB',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
        ]
      },
      {
        path: '/task/troubleShooting/system-data',
        title: 'SystemData',
        moduleName: 'troubleshooting-systemData',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'TRSSD',
        submenu: [], accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '/task/troubleShooting/submitted-data',
        title: 'Submitted Data',
        moduleName: 'troubleshooting-submittedData',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'TRSSUD',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '/task/troubleShooting/McData',
        title: 'TargetAccount',
        moduleName: 'troubleshooting-TargetAccount',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'TTARA',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      // {
      //   path: '',
      //   title: 'QC',
      //   moduleName: 'QC',
      //   iconType: 'material-icons',
      //   icon: 'group_add',
      //   class: 'ml-sub-menu',
      //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   submenu: [
      //     {
      //       path: 'under_dev',
      //       title: 'Approval Upload',
      //       moduleName: '',
      //       iconType: 'material-icons',
      //       icon: 'space_dashboard',
      //       class: 'ml-menu',
      //       groupTitle: false,
      //       badge: '',
      //       badgeClass: '',
      //       submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      //     },
      //     {
      //       path: 'under_dev',
      //       title: 'Reject Upload',
      //       moduleName: '',
      //       iconType: 'material-icons',
      //       icon: 'space_dashboard',
      //       class: 'ml-menu',
      //       groupTitle: false,
      //       badge: '',
      //       badgeClass: '',
      //       submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      //     },
      //     {
      //       path: 'under_dev',
      //       title: 'Reapproval Upload',
      //       moduleName: '',
      //       iconType: 'material-icons',
      //       icon: 'space_dashboard',
      //       class: 'ml-menu',
      //       groupTitle: false,
      //       badge: '',
      //       badgeClass: '',
      //       submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      //     }
      //   ]
      // },
      {
        path: '',
        title: 'Submission',
        moduleName: 'troubleshooting-submission',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/submission/troubleShooting/batchCreation',
            title: 'Batch Creation',
            moduleName: 'troubleshooting-submission',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'TRSBTC',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/submission/troubleShooting/submittedData',
            title: 'Batch Submitted',
            moduleName: 'troubleshooting-submission',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'TRSBTL',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
        ]
      },
      {
        path: '',
        title: 'Reports',
        moduleName: 'troubleshooting-reports',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/task/troubleShooting/achieved',
            title: 'Achieved',
            moduleName: 'troubleshooting-reports',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'TRSACH',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/task/troubleShooting/onlyvisit',
            title: 'Only Visit',
            moduleName: 'troubleshooting-reports',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'TRSOV',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/reports/troubleshooting/DayWiseReport',
            title: 'Day Wise Report',
            moduleName: 'troubleshooting-reports',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'TRSDR',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: 'under-dev',
            title: 'Month Wise Report',
            moduleName: 'troubleshooting-reports',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'TRSMR',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          // {
          //   path: '/reports/troubleshooting/UserWiseReport',
          //   title: 'Team Performance Report',
          //   moduleName: 'troubleshooting-reports',
          //   iconType: 'material-icons',
          //   icon: 'space_dashboard',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   permissionCode: 'TRSTP',
          //   submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // },
          {
            path: '/reports/troubleshooting/UserWiseReport',
            title: 'Day Wise Team Performance',
            moduleName: 'troubleshooting-reports',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'TRSDTP',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },

        ]
      },
      {
        path: '',
        title: 'History',
        moduleName: 'troubleshooting-history',
        iconType: 'material-icons',
        icon: 'history_toggle_off',
        class: 'ml-sub-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        submenu: [
          //{
          //  path: '/history/account',
          //  title: 'Reading History',
          //  moduleName: 'history',
          //  iconType: 'material-icons',
          //  icon: 'group_add',
          //  class: 'ml-menu',
          //  groupTitle: false,
          //  badge: '',
          //  badgeClass: '',
          //  submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          //},
          {
            path: '/history/reassign_history',
            title: 'Reassign History',
            moduleName: 'troubleshooting-history',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'TRSRESH',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
        ]
      },
    ]
  },
  {
    path: '',
    title: 'Task',
    moduleName: 'Task',
    iconType: 'material-icons',
    icon: 'task',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
    submenu: [
      {
        path: '',
        title: 'Dashboard',
        moduleName: 'sitesurvey-dashboard',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [{
          path: '/dashboard/task/main',
          title: 'Dashboard',
          moduleName: 'task-dashboard',
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-menu',
          accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: false,
          badge: '',
          badgeClass: '',
          permissionCode: 'TMD',
          submenu: []
        },
        {
          path: '/dashboard/task-survey',
          title: 'Task Survey Dashboard',
          moduleName: 'sitesurvey-dashboard',
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-menu',
          accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: false,
          badge: '',
          badgeClass: '',
          permissionCode: 'TSD',
          submenu: []
        }
    ]
      },
      {
        path: '',
        title: 'Target',
        moduleName: 'reading-target',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: true,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/target/upload/task',
            title: 'Target Upload',
            moduleName: 'task-target',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'TTU',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          }
        ]
      },
      {
        path: '/task/task/unread',
        title: 'Open',
        moduleName: 'task-unread',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'TO',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '',
        title: 'Reassignment',
        moduleName: 'troubleshooting-reassignment',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-sub-menu',
        groupTitle: true,
        badge: '',
        badgeClass: '',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        submenu: [
          {
            path: '/task/task/reassign-bulk',
            title: 'Task Bulk',
            moduleName: 'task-reassignment',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'TRB',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
        ]
      },
      {
        path: '/task/task/target-account',
        title: 'Target Account',
        moduleName: 'task-targetAccount',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'TTA',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '/task/task/system-data',
        title: 'System Data',
        moduleName: 'task-systemdata',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'TSA',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '/task/task/submitted-data',
        title: 'Submitted Data',
        moduleName: 'task-submitteddata',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'TSSD',
        submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      },
      {
        path: '',
        title: 'QC',
        moduleName: 'task-qc',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/qc/task/Upload/Approved',
            title: 'Approval Upload',
            moduleName: 'qc',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'TQCA',
            submenu: []
          },
          {
            path: '/qc/task/Upload/Rejected',
            title: 'Reject Upload',
            moduleName: 'qc',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'TQCR',
            submenu: []
          },
          // {
          //   path: '/qc/task/Upload/ReApproved',
          //   title: 'Reapproval Upload',
          //   moduleName: 'qc',
          //   iconType: 'material-icons',
          //   icon: 'group_add',
          //   class: 'ml-menu',
          //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   // permissionCode: 'MDMQCREU',
          //   submenu: []
          // },
    ]},
      {
        path: '',
        title: 'Submission',
        moduleName: 'sitesurvey-submission',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/submission/task/create',
            title: 'Batch Creation',
            moduleName: 'task-create',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'TSC',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/submission/task/list',
            title: 'Batch Submitted',
            moduleName: 'task-list',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'TSL',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
        ]
      },
      
      {
        path: '',
        title: 'Reports',
        moduleName: 'reding-reports',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/task/task/reports/Achieved',
            title: 'Achieved',
            moduleName: 'reading-reports-achieved',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'TRA',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/task/task/reports/OnlyVisit',
            title: 'Only Visit',
            moduleName: 'reading-reports-onlyVisit',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'TROV',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/task/task/reports/DateWise',
            title: 'Date-Wise-Report',
            moduleName: 'reading-reports-dateWise',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'TRD',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          }, {
            path: '/task/task/reports/TechnicianPerformance',
            title: 'Team Performance',
            moduleName: 'reading-reports-technicianPerformance',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'TRTP',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/task/task/reports/monthly-report',
            title: 'Monthly Report',
            moduleName: 'reading-reports-MonthlyReport',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'TMR',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
        ]},
        {
          path: '',
          title: 'History',
          moduleName: 'sitesurvey-history',
          iconType: 'material-icons',
          icon: 'history_toggle_off',
          class: 'ml-sub-menu',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          submenu: [
            //{
            //  path: '/history/account',
            //  title: 'Reading History',
            //  moduleName: 'history',
            //  iconType: 'material-icons',
            //  icon: 'group_add',
            //  class: 'ml-menu',
            //  groupTitle: false,
            //  badge: '',
            //  badgeClass: '',
            //  submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            //},
            {
              path: '/history/task/reassign_history',
              title: 'Reassign History',
              moduleName: 'history',
              iconType: 'material-icons',
              icon: 'group_add',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              permissionCode: 'THRH',
              submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
          ]
        },
    ]},
    {
      path: '',
      title: 'During Replacement QC',
      moduleName: 'During replacement-qc',
      iconType: 'material-icons',
      icon: 'update',
      class: 'menu-toggle',
      groupTitle: false,
      badge: '',
      badgeClass: '',
      accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      submenu: [
        {
          path: '',
          title: ' Target',
          moduleName: 'replacement-qc-During-Target Upload',
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-sub-menu-2',
          accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: false,
          badge: '',
          badgeClass: '',
          submenu: [  {
            path: '/target/upload/during-replacement',
            title: 'Target Upload',
            moduleName: 'replacement-qc-during-replacement-target-upload',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'RQCDRTU',
            submenu: []
          }, ]}, 
          {
            path: '/replacement-qc/during-replacement/unread',
            title: 'Unread',
            moduleName: 'replacement-qc-during-replacement-unread',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'RQCDROD',
            submenu: []
          },
          {
          path: '/replacement-qc/during-replacement/system-data',
          title: 'System Data',
          moduleName: 'replacement-qc',
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-menu',
          accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: false,
          badge: '',
          badgeClass: '',
          permissionCode: 'RQCDRSD',
          submenu: []
        },
         {
          path: '',
          title: ' Reassignment',
          moduleName: 'replacement-qc-During-Reassign',
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-sub-menu-2',
          accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: false,
          badge: '',
          badgeClass: '',
          submenu: [  {
            path: '/replacement-qc/during-replacement/reassign-bulk',
            title: 'Reassign Bulk',
            moduleName: 'replacement-qc-reassign-bulk',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'RQCDRRB',
            submenu: []
          }, ]},
          {
            path: '',
            title: ' History',
            moduleName: 'replacement-qc-During-History',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-sub-menu-2',
            accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [  {
              path: '/history/during-replacement/reassign_history',
              title: 'Reassign History',
              moduleName: 'replacement-qc-during-replacement-Reassign-History',
              iconType: 'material-icons',
              icon: 'group_add',
              class: 'ml-menu',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
              groupTitle: false,
              badge: '',
              badgeClass: '',
              permissionCode: 'RQCDRRH',
              submenu: []
            }, ]}
        ]
      },
      {
        path: '',
        title: 'After Replacement QC',
        moduleName: 'After-replacement-qc',
        iconType: 'material-icons',
        icon: 'verified_user',
        class: 'menu-toggle',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        submenu: [ 
          //  {
          // path: '',
          // title: ' Target',
          // moduleName: 'replacement-qc-After-Target Upload',
          // iconType: 'material-icons',
          // icon: 'group_add',
          // class: 'ml-sub-menu-2',
          // accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // groupTitle: false,
          // badge: '',
          // badgeClass: '',
          // submenu: [  {
          //   path: '/target/upload/after-replacement/target-upload',
          //   title: 'Target Upload',
          //   moduleName: 'replacement-qc-after-replacement-target-upload',
          //   iconType: 'material-icons',
          //   icon: 'group_add',
          //   class: 'ml-menu',
          //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   permissionCode: '',
          //   submenu: []
          // }, ]}, 
          // {
          //   path: '/replacement-qc/after-replacement/unread',
          //   title: 'Unread',
          //   moduleName: 'replacement-qc-after-replacement-unread',
          //   iconType: 'material-icons',
          //   icon: 'group_add',
          //   class: 'ml-menu',
          //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          //   groupTitle: false,
          //   badge: '',
          //   badgeClass: '',
          //   permissionCode: '',
          //   submenu: []
          // },
          {
          path: '/replacement-qc/after-replacement/system-data',
          title: 'System Data',
          moduleName: 'replacement-qc',
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-menu',
          accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: false,
          badge: '',
          badgeClass: '',
          permissionCode: 'RQCARSD',
          submenu: []
        },
        //  {
        //   path: '',
        //   title: ' Reassignment',
        //   moduleName: 'replacement-qc-after-Reassign',
        //   iconType: 'material-icons',
        //   icon: 'group_add',
        //   class: 'ml-sub-menu-2',
        //   accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        //   groupTitle: false,
        //   badge: '',
        //   badgeClass: '',
        //   submenu: [  {
        //     path: '/replacement-qc/after-replacement/reassign-bulk',
        //     title: 'Reassign Bulk',
        //     moduleName: 'replacement-qc-reassign-bulk',
        //     iconType: 'material-icons',
        //     icon: 'group_add',
        //     class: 'ml-menu',
        //     accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        //     groupTitle: false,
        //     badge: '',
        //     badgeClass: '',
        //     permissionCode: '',
        //     submenu: []
        //   }, ]}
        ]},
   

  /* Meter Communication*/
  // {
  //   path: '',
  //   title: 'Meter Communication',
  //   moduleName: 'MeterCommunication',
  //   iconType: 'material-icons',
  //   icon: 'settings_input_antenna',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
  //   submenu: [
  //     {
  //       path: 'under_dev',
  //       title: 'Bulk Upload',
  //       moduleName: 'MeterCommunication',
  //       iconType: 'material-icons',
  //       icon: 'group_add',
  //       class: 'ml-menu',
  //       accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       submenu: []
  //     },
  //   ]
  // },

  /* Account History */
  {
    path: '',
    title: 'Account History',
    moduleName: 'AccountHistory',
    iconType: 'material-icons',
    icon: 'manage_history',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
    submenu: [
      {
        path: '/history/account-new/meter-reading/M02131',
        title: 'Account History',
        moduleName: 'dashboard',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'ACCH',
        submenu: []
      },
    ]
  },

    /* Aministration */
    {
      path: '',
      title: 'Administration',
      moduleName: 'administration',
      iconType: 'material-icons',
      icon: 'admin_panel_settings',
      class: 'menu-toggle',
      groupTitle: false,
      badge: '',
      badgeClass: '',
      accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      submenu: [
        {
          path: '',
          title: 'Masters',
          moduleName: 'Admin-masters',
  
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-sub-menu',
          accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: true,
          badge: '',
          badgeClass: '',
          submenu: [
            {
              path: '/accountsMaster/master/service-type',
              title: 'Service ',
              moduleName: 'account-master-service-type',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              submenu: [],
              permissionCode:'AMST',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            {
              path: '/accountsMaster/master/picture-type',
              title: 'Picture Type ',
              moduleName: 'account-master-picture-type',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              submenu: [],
              permissionCode:'AMPT',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            }, {
              path: '/accountsMaster/master/dynamic-column',
              title: 'Dynamic Column ',
              moduleName: 'account-master-dynamicColumn',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              submenu: [],
              permissionCode:'ADCM',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            {
              path: '/accountsMaster/master/column-header',
              title: 'DropDown-Description',
              moduleName: 'account-master-columnheader',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              submenu: [],
              permissionCode:'ACHM',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            {
              path: '/accountsMaster/master/reason',
              title: 'Reason',
              moduleName: 'account-master',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              submenu: [],
              permissionCode:'ARM',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            {
              path: '/accountsMaster/master/status',
              title: 'Status ',
              moduleName: 'account-master',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              submenu: [],
              permissionCode:'ASM',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            {
              path: '/accountsMaster/master/cycle',
              title: 'Cycle',
              moduleName: 'account-master-zone',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              submenu: [],
              permissionCode:'ACYM',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            // {
            //   path: '/accountsMaster/master/construction-type',
            //   title: 'Construction Type',
            //   moduleName: 'account-master-construction-type',
            //   iconType: 'material-icons',
            //   icon: 'space_dashboard',
            //   class: 'ml-menu',
            //   groupTitle: false,
            //   badge: '',
            //   badgeClass: '',
            //   submenu: [],
            //   permissionCode:'ACTM',
            //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            // },
            {
              path: '/accountsMaster/master/dynamic-column-type',
              title: 'Dynamic Column Type',
              moduleName: 'account-master-dynamic-column-type',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              submenu: [],
              permissionCode:'ADCTM',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            // {
            //   path: '/accountsMaster/master/property-type',
            //   title: 'Property Type',
            //   moduleName: 'account-master-property-type',
            //   iconType: 'material-icons',
            //   icon: 'space_dashboard',
            //   class: 'ml-menu',
            //   groupTitle: false,
            //   badge: '',
            //   badgeClass: '',
            //   submenu: [],
            //   permissionCode:'APTM',
            //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            // },
            // {
            //   path: '/accountsMaster/master/tenant-type',
            //   title: 'Tenant Type',
            //   moduleName: 'account-master-tenant-type',
            //   iconType: 'material-icons',
            //   icon: 'space_dashboard',
            //   class: 'ml-menu',
            //   groupTitle: false,
            //   badge: '',
            //   badgeClass: '',
            //   submenu: [],
            //   permissionCode:'ATTM',
            //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            // },
            {
              path: '/accountsMaster/master/task-drop-down',
              title: 'Task Drop-Down',
              moduleName: 'account-master-Task-Drop-Down',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              submenu: [],
              permissionCode:'ATDDM',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            {
              path: '/accountsMaster/master/task-building-type',
              title: 'Task Building Type',
              moduleName: 'account-master-Task-building-type',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              submenu: [],
              permissionCode:'ATBTM',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            // {
            //   path: '/accountsMaster/master/valve-condition',
            //   title: 'Valve Condition',
            //   moduleName: 'account-master-valve-condition',
            //   iconType: 'material-icons',
            //   icon: 'space_dashboard',
            //   class: 'ml-menu',
            //   groupTitle: false,
            //   badge: '',
            //   badgeClass: '',
            //   submenu: [],
            //   permissionCode:'AVCM',
            //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            // },
            // {
            //   path: '/accountsMaster/master/mechanical-amr',
            //   title: 'Mechanical OR AMR',
            //   moduleName: 'account-master-mechanical-amr',
            //   iconType: 'material-icons',
            //   icon: 'space_dashboard',
            //   class: 'ml-menu',
            //   groupTitle: false,
            //   badge: '',
            //   badgeClass: '',
            //   submenu: [],
            //   permissionCode:'AMAMRM',
            //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            // },
            // {
            //   path: '/accountsMaster/master/MeterStatus',
            //   title: 'Meter Status',
            //   moduleName: 'account-master-Meter-Status',
            //   iconType: 'material-icons',
            //   icon: 'space_dashboard',
            //   class: 'ml-menu',
            //   groupTitle: false,
            //   badge: '',
            //   badgeClass: '',
            //   submenu: [],
            //   permissionCode:'AMSM',
            //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            // },
            {
              path: '/accountsMaster/master/survey-reason',
              title: 'Survey Suborinate Reason',
              moduleName: 'account-master-survey-reason',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              submenu: [],
              permissionCode:'ASRM',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            {
              path: '/accountsMaster/geo-code-mapping',
              title: 'Geo Code Mapping ',
              moduleName: 'account-master-geoCode-mapping',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              submenu: [],
              permissionCode:'AGCMS',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
        ]},
        {
          path: '',
          title: 'User Management',
          moduleName: 'user-management',
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-sub-menu',
          accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: false,
          badge: '',
          badgeClass: '',
          submenu: [
           
            {
              path: '/administration/employees',
              title: 'Employees',
              moduleName: 'employees',
              iconType: 'material-icons',
              icon: 'group_add',
              class: 'ml-menu',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
              groupTitle: false,
              badge: '',
              badgeClass: '',
              permissionCode:'ADUME',
              submenu: []
            },
            {
              path: '/administration/user',
              title: 'User',
              moduleName: 'user',
              iconType: 'material-icons',
              icon: 'group_add',
              class: 'ml-menu',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
              groupTitle: false,
              badge: '',
              permissionCode:'ADUMU',
              badgeClass: '',
              submenu: []
            },
            {
              path: '/administration/user-rights',
              title: 'User Rights',
              moduleName: 'user-rights',
              iconType: 'material-icons',
              icon: 'group_add',
              class: 'ml-menu',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
              groupTitle: false,
              badge: '',
              badgeClass: '',
              permissionCode:'ADUMUR',
              submenu: []
            },
            {
              path: '/administration/roles',
              title: 'Roles',
              moduleName: 'roles',
              iconType: 'material-icons',
              icon: 'group_add',
              class: 'ml-menu',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
              groupTitle: false,
              badge: '',
              badgeClass: '',
              permissionCode:'ADMR',
              submenu: []
            },
            {
              path: '/administration/permissions',
              title: 'Permissions',
              moduleName: 'permissions',
              iconType: 'material-icons',
              icon: 'group_add',
              class: 'ml-menu',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
              groupTitle: false,
              badge: '',
              badgeClass: '',
              permissionCode:'ADUMPR',
              submenu: []
            },
            {
              path: '/administration/attendance-report',
              title: 'Attendance Report',
              moduleName: 'permissions',
              iconType: 'material-icons',
              icon: 'group_add',
              class: 'ml-menu',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
              groupTitle: false,
              badge: '',
              badgeClass: '',
              permissionCode:'ARU',
              submenu: []
            },
          ]
  
        }
      ]
    },
    // attendance
    {
      path: '',
      title: 'Attendance',
      moduleName: 'Attendance',
      iconType: 'material-icons',
      icon: 'query_builder',
      class: 'menu-toggle',
      groupTitle: false,
      badge: '',
      badgeClass: '',
      accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      submenu: [
        {
          path: '/attendence/dashboard',
          title: 'Dashboard',
          moduleName: 'attendance-dashboard',
          iconType: 'material-icons',
          icon: 'space_dashboard',
          class: 'ml-menu',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          submenu: [],
          permissionCode:'ASD',
          accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        },
        {
          path: '/attendence/monthly-report',
          title: 'Monthly Report',
          moduleName: 'attendance-monthly-report',
          iconType: 'material-icons',
          icon: 'space_dashboard',
          class: 'ml-menu',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          submenu: [],
          permissionCode:'ASMR',
          accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        },
        // {
        //   path: '/attendence/monthly-present-absent-report',
        //   title: 'Monthly Present/Absent Report',
        //   moduleName: 'attendance-monthly-present-absent',
        //   iconType: 'material-icons',
        //   icon: 'space_dashboard',
        //   class: 'ml-menu',
        //   groupTitle: false,
        //   badge: '',
        //   badgeClass: '',
        //   submenu: [],
        //   permissionCode:'',
        //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        // }
      ]},
  
    // Account
    {
      path: '',
      title: 'Accounts Master',
      moduleName: 'Accounts',
      iconType: 'material-icons',
      icon: 'account_box',
      class: 'menu-toggle',
      groupTitle: false,
      badge: '',
      badgeClass: '',
      accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
      submenu: [
        {
          path: '',
          title: 'Accounts',
          moduleName: 'accounts',
  
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-sub-menu',
          accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: true,
          badge: '',
          badgeClass: '',
          submenu: [
        {
          path: '/accountsMaster/account',
          title: 'Accounts',
          moduleName: 'account-master-zone',
          iconType: 'material-icons',
          icon: 'space_dashboard',
          class: 'ml-menu',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          submenu: [],
          permissionCode:'ACCM',
          accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        },
        {
          path: '/target/upload/account-migration',
          title: 'Account Migration',
          moduleName: 'reading-target',
          iconType: 'material-icons',
          icon: 'space_dashboard',
          class: 'ml-menu',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          submenu: [],
          permissionCode: 'AMG',
          accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        },
        {
          path: '/accountsMaster/uploadAccount',
          title: 'Accounts Upload',
          moduleName: 'account-master-zone',
          iconType: 'material-icons',
          icon: 'space_dashboard',
          class: 'ml-menu',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          submenu: [],
          permissionCode:'AU',
          accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        },
        
        {
          path: '/target/findings/geocode',
          title: 'GeoCode Findings',
          moduleName: 'reading-target',
          iconType: 'material-icons',
          icon: 'space_dashboard',
          class: 'ml-menu',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          submenu: [],
          permissionCode: 'AGCF',
          accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        },]},
        {
          path: '',
          title: 'Master',
          moduleName: 'accounts-master',
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-sub-menu',
          accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: true,
          badge: '',
          badgeClass: '',
          submenu: [
            {
              path: '/accountsMaster/master/zone',
              title: 'Zone',
              moduleName: 'account-master-zone',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              submenu: [],
              permissionCode:'ZM',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            {
              path: '/accountsMaster/master/geocode',
              title: 'GeoCode',
              moduleName: 'account-master-geocode',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              submenu: [],
              permissionCode:'GCM',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            {
              path: '/accountsMaster/master/priceCategory',
              title: 'Price Category',
              moduleName: 'account-master-priceCategory',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              submenu: [],
              permissionCode:'AMMPC',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            {
              path: '/accountsMaster/master/country',
              title: 'Country',
              moduleName: 'account-master-country',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              submenu: [],
              permissionCode:'AMMC',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            {
              path: '/accountsMaster/master/client',
              title: 'Client',
              moduleName: 'account-master-client',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              permissionCode:'CLM',
              submenu: [],
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            {
              path: '/accountsMaster/master/company',
              title: 'Company',
              moduleName: 'account-master-company',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              permissionCode:'COM',
              submenu: [],
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            {
              path: '/accountsMaster/master/meterType',
              title: 'Meter Type',
              moduleName: 'account-master-meterType',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              submenu: [],
              permissionCode:'MTM',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            {
              path: '/accountsMaster/master/governorate',
              title: 'Governorate',
              moduleName: 'account-master-governorate',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              permissionCode:'GOVM',
              submenu: [],
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            {
              path: '/accountsMaster/master/accountCategory',
              title: 'Account Category',
              moduleName: 'account-master-accountCategory',
              iconType: 'material-icons',
              icon: 'space_dashboard',
              class: 'ml-menu',
              groupTitle: false,
              badge: '',
              badgeClass: '',
              submenu: [],
              permissionCode:'ACM',
              accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            },
            
          ]
        },
      ]
    },

 
  // Inventory
  {
    path: '',
    title: 'Inventory',
    moduleName: 'Inventory',
    iconType: 'material-icons',
    icon: 'view_in_ar',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
    submenu: [
      {
        path: '',
        title: 'Stock',
        moduleName: 'stock',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: true,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/inventory/stock-list',
        title: 'Open Stock',
        moduleName: 'stock-list',
        iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode:'ISTL',
            submenu: []
      },
      {
        path: '/inventory/stock-history',
        title: 'Stock History',
        moduleName: 'stock-list',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'ISH',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/inventory/stock-count',
            title: 'Stock Count',
            moduleName: 'stock-count',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode:'TSTC',
            submenu: []
          },
        
        ]
      },
      {
        path: '',
        title: 'Good Received',
        moduleName: 'inventory-good recived',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: true,
        badge: '',
        badgeClass: '',
        submenu: [

      {
        path: '/inventory/goods-received-note',
        title: 'Good Received Note',
        moduleName: 'goods-received-note',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permissionCode:'IGRN'
      },
      {
        path: '/inventory/goods-received-log',
        title: 'GRN Log',
        moduleName: 'goods-received-note-log',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permissionCode:'IGRN'
      }
    ]},
      {
        path: '/inventory/gsm-target',
        title: 'GSM Upload',
        moduleName: 'gsm-number-target',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permissionCode:'IGSMN'
      },
      {
        path: '',
        title: 'Issue',
        moduleName: 'issue',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: true,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/inventory/issue',
        title: 'Issue Material',
        moduleName: 'issue',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'IIS',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/inventory/issue-count',
        title: 'Issue Count',
        moduleName: 'issue-count',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'IISC',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },{
            path: '/inventory/issue-history',
        title: 'Issue History',
        moduleName: 'issue-count',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
            groupTitle: false,
            badge: '',
            badgeClass: 'IISH',
            submenu: []
          },
        
        ]
      },
      {
        path: '/inventory/material-return-note',
        title: 'Material Return Note',
        moduleName: 'material-return-note',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permissionCode:'IMRN'
      },
      {
        path: '/inventory/pairing-history',
        title: 'Pairing History',
        moduleName: 'pairing-history',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permissionCode: 'IPH'
      },
      
      {
        path: '/inventory/Delivery-note',
        title: 'Delivery Note',
        moduleName: 'delivery-note',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permissionCode: 'IDN',
      },

      {
        path: '',
        title: 'Reports',
        moduleName: 'reports-inventory',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: true,
        badge: '',
        badgeClass: '',
        submenu: [
               
      {
        path: '/inventory/reports/goods-return-note',
        title: 'Goods Return Note',
        moduleName: 'goods-return-note-reports',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permissionCode: 'IRGRN',
      },
      {
        path: '/inventory/reports/material-return-note',
        title: 'Material Return Note',
        moduleName: 'material-return-note-reports',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permissionCode: 'IRMRN',
      },
      {
        path: '/inventory/reports/issue',
        title: 'Issue',
        moduleName: 'issue-reports',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permissionCode: 'IRIS',
      },
    ]
  },
    ]
  },
  {
    path: '',
    title: 'Inspection',
    moduleName: 'ReplacementInspection',
    iconType: 'material-icons',
    icon: 'content_paste_search',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
    submenu: [
      {
        path: '',
        title: 'Dashboard',
        moduleName: 'ReplacementInspection',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [{
          path: '/inspection/dashboard',
          title: 'Inspection-Dashboard',
          moduleName: 'ReplacementInspection',
          iconType: 'material-icons',
          icon: 'group_add',
          class: 'ml-menu',
          accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          groupTitle: false,
          badge: '',
          badgeClass: '',
          permissionCode: 'RID',
          submenu: []
        }]
      },
      {
        path: '',
        title: 'Target',
        moduleName: 'reading-target',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: true,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/inspection/target-upload',
            title: 'Target Upload',
            moduleName: 'Replacement-Inspection',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'ITU',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          }
        ]
      },
      {
        path: '/inspection/unread',
        title: 'Unread',
        moduleName: 'Replacement-Inspection',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permissionCode:'IUS'
      },
      {
        path: '',
        title: 'Reassignment',
        moduleName: 'reading-reassignment',
        iconType: 'material-icons',
        icon: 'space_dashboard',
        class: 'ml-sub-menu',
        groupTitle: true,
        badge: '',
        badgeClass: '',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        submenu: [
          // {
          //   path: '/task/meterreading/target-reasssign',
          //   title: 'Reassignment',
          //   moduleName: 'reading-reassignment',
          //   iconType: 'material-icons',
          //   icon: 'space_dashboard',
          //   class: 'ml-menu',
          //   groupTitle: false,
          //   badge: '',
          //   permissionCode: 'MDMGR',
          //   badgeClass: '',
          //   submenu: [],
          //   accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          // },
          {
            path: '/inspection/reassign-bulk',
            title: 'Reassign Bulk',
            moduleName: 'Replacement-Inspection',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
            permissionCode: 'IRB',
            accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
        ]
      },
      {
        path: '/inspection/target-account',
        title: 'Target Account',
        moduleName: 'Replacement-Inspection',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permissionCode:'ITA'
      },
      {
        path: '/inspection/system-data',
        title: 'System Data',
        moduleName: 'Replacement-Inspection',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permissionCode:'ISD'
      },
      {
        path: '/inspection/submitted-data',
        title: 'Submitted Data',
        moduleName: 'Replacement-Inspection',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permissionCode:'ISUD'
      },
      {
        path: '',
        title: 'Submission',
        moduleName: 'Replacement-Inspection',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/inspection/batch-creation',
            title: 'Batch Creation',
            moduleName: 'Replacement-Inspection',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'ISBC',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/inspection/batch-submission',
            title: 'Batch Submitted',
            moduleName: 'Replacement-Inspection',
            iconType: 'material-icons',
            icon: 'space_dashboard',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'IBSS',
            submenu: [], accessLevel: ['medc', 'superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
        ]
      },
      {
        path: '',
        title: 'Reports',
        moduleName: 'Replacement-Inspection-reports',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-sub-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/inspection/achieved',
            title: 'Achieved',
            moduleName: 'Replacement-Inspection-achieved',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'IRAS',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
          {
            path: '/inspection/only-visit',
            title: 'Only Visit',
            moduleName: 'Replacement-Inspection-onlyvisit',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: 'IROV',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          }
        
        ]
      },
      {
        path: '',
        title: 'History',
        moduleName: 'sitesurvey-history',
        iconType: 'material-icons',
        icon: 'history_toggle_off',
        class: 'ml-sub-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        submenu: [
          //{
          //  path: '/history/account',
          //  title: 'Reading History',
          //  moduleName: 'history',
          //  iconType: 'material-icons',
          //  icon: 'group_add',
          //  class: 'ml-menu',
          //  groupTitle: false,
          //  badge: '',
          //  badgeClass: '',
          //  submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          //},
          {
            path: '/history/inspection/reassign_history',
            title: 'Reassign History',
            moduleName: 'sitesurvey-history',
            iconType: 'material-icons',
            icon: 'group_add',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            permissionCode: '',
            submenu: [], accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
          },
        ]
      },
    ]},

  /* Settings */
  {
    path: '',
    title: 'Settings',
    moduleName: 'Settings',
    iconType: 'material-icons',
    icon: 'manage_accounts',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
    submenu: [
      {
        path: '/extra-pages/changePassword',
        title: 'Change Password',
        moduleName: 'dashboard',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'CPW',
        submenu: []
      },
      {
        path: '/settings/email-alerts',
        title: 'Email Alerts',
        moduleName: 'email-alerts',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'EAS',
        submenu: []
      },
      {
        path: '/settings/alerts-history',
        title: 'Alerts history',
        moduleName: 'alerts-history',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'AHS',
        submenu: []
      },
      {
        path: '/settings/deletion-history',
        title: 'Deletion history',
        moduleName: 'alerts-history',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'SDH',
        submenu: []
      },
      
    ]
  },

  {
    path: '',
    title: 'Tracking',
    moduleName: 'Tracking',
    iconType: 'material-icons',
    icon: 'pin_drop',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
    submenu: [
      {
        path: '/tracking/Track',
        title: 'Track',
        moduleName: 'Track',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'LTT',
        submenu: []
      },
      {
        path: '/tracking/History',
        title: 'History',
        moduleName: 'Track',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'LTH',
        submenu: []
      },
      {
        path: '/tracking/IndividualTracking',
        title: 'Individual Track',
        moduleName: 'Track',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'LTIT',
        submenu: []
      },
      {
        path: '/tracking/Tracking-status',
        title: 'Tracking-Status',
        moduleName: 'Track',
        iconType: 'material-icons',
        icon: 'group_add',
        class: 'ml-menu',
        accessLevel: ['superadmin', 'supervisor', 'quality team', 'zonal manager', 'operational manager'],
        groupTitle: false,
        badge: '',
        badgeClass: '',
        permissionCode: 'LTS',
        submenu: []
      },
    ]
  },
 
];


export function getRedirectUrl(userPermissions: IPermission[], returnUrl: string) {
  let allRoutes: RouteInfo[] = ROUTES.slice();
  let permittedRoutes: RouteInfo[] = [];
  let redirectUrl: string = '';
  allRoutes.forEach(firstLevel => {
    firstLevel.submenu.forEach(secondLevel => {
      if (userPermissions?.length > 0) {
      if (userPermissions.some((x: IPermission) => {
        return Array.isArray(secondLevel?.permissionCode) ? secondLevel?.permissionCode.includes(x.Code) : x.Code === secondLevel?.permissionCode;
      })) {
        permittedRoutes.push(secondLevel);
      } else {
        secondLevel.submenu.forEach(thirdLevel => {
          if (userPermissions.some((x: IPermission) => x.Code === thirdLevel?.permissionCode)) {
            permittedRoutes.push(thirdLevel);
          }
        })
      }
    }
    })
  })
  if (permittedRoutes.length) {
    if (!permittedRoutes.some(route => route.path === returnUrl)) {
      redirectUrl = permittedRoutes[0]?.path;
    } else {
      redirectUrl = returnUrl;
    }
  }
  
  return redirectUrl || '/authentication/signin';
}
